import React, { useState, useEffect, useRef } from "react"
import Img from "gatsby-image"
import Plx from "react-plx"
import testlogo from "../../images/logo.png"
import Lottie from "../LottieWithAnimation/LottieWithAnimation"
import * as animationData from "../../animation/artifier.json"
import "./Hero.css"

const parallaxData = [
  {
    start: "self",
    duration: "60vh",
    properties: [
      {
        startValue: 1,
        endValue: 0.4,
        property: "scale",
      },
      {
        startValue: -25,
        endValue: -56,
        property: "translateY",
        unit: "vh",
      },
    ],
  },
]

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData.default,
  speed: 0.1,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const Hero = ({ isHidden, setOverlapping, percentage }) => {
  return (
    <section className="hero">
      <Plx
        className="logo-container"
        parallaxData={parallaxData}
        onPlxEnd={() => {
          setOverlapping(true)
        }}
        onPlxStart={() => {
          setOverlapping(false)
        }}
      >
        <Lottie options={defaultOptions} percentage={percentage} />
        <h2
          className={`logo__text ${isHidden ? "hidden" : ""} `}
          style={{ textAlign: "center" }}
        >
          Artifier <br />
          Management
        </h2>
      </Plx>
    </section>
  )
}

export default Hero
