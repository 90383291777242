import React from "react"
import MarkdownContent from "../MarkdownContent"

import "./About.css"

const about = ({ description, sticky }) => {
  return (
    <section id="about" className={`${sticky ? "is-sticky" : ""}`}>
      <div className="container">
        <MarkdownContent className="about__description" content={description} />
      </div>
    </section>
  )
}

export default about
