import React from "react"
import { Link } from "gatsby"
import MarkdownContent from "../MarkdownContent"
import "./Contact.css"

const Contact = ({ contact }) => {
  return (
    <section id="contact">
      <div className="container">
        <MarkdownContent
          className="contact__header"
          content={contact.headline}
        />
        <p className="contact__info">
          {contact.adress}
          <br />
          {contact.adress2}
        </p>
        <p className="contact__info">
          {contact.phonenumber}
          <br />
          {contact.email}
          <br />
          {contact.website}
        </p>
      </div>
      <ul className="contact__list">
        <li className="contact__list__item">
          <Link to="/imprint">Imprint</Link>
        </li>
        <li className="contact__list__item">
          <Link to="/imprint#legal-notice-data-privacy">
            Legal Notice, Data Privacy
          </Link>
        </li>
      </ul>
    </section>
  )
}

export default Contact
