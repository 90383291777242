import React from "react"
import Img from "gatsby-image"
import Plx from "react-plx"
import MarkdownContent from "./MarkdownContent"
import "./artist.css"

const artist = props => {
  const { name, description, image, index } = props
  const shapes = []

  if (index % 2 === 0) {
    shapes.push({
      div: <div key="diagonal" className="diagonal"></div>,
      speed: 500,
    })
    shapes.push({
      div: <div key="dot" className="dot"></div>,
      speed: 200,
    })
  } else {
    shapes.push({
      div: <div key="dot" className="dot dot--secondary"></div>,
      speed: 200,
    })
    shapes.push({
      div: <div key="arrow" className="arrow"></div>,
      speed: 600,
    })
  }

  return (
    <>
      <div className="container">
        {shapes.map(shape => (
          <Plx
            key={shape.div.key}
            className="parallax-z-index"
            parallaxData={[
              {
                start: "self",
                duration: ".artist",
                properties: [
                  {
                    startValue: shape.speed,
                    endValue: -200,
                    property: "translateY",
                  },
                ],
              },
            ]}
          >
            {shape.div}
          </Plx>
        ))}
        <div className="artist">
          <h2 className="artist__name">{name}</h2>
          <MarkdownContent
            className="artist__description"
            content={description}
          />
          <div className="artist__image">
            {image && image.childImageSharp ? (
              <Img fluid={image.childImageSharp.fluid} alt={name} />
            ) : (
              <img src={image} alt={name} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default artist
