import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/Hero/Hero"
import About from "../components/About/About"
import Artist from "../components/artist"
import "../css/grid.css"
import "../css/hamburger.css"
import Contact from "../components/Contact/Contact"
import Navbar from "../components/Navbar/Navbar"

export const IndexTemplate = ({
  logo,
  contact,
  description,
  artists,
  logoSmall,
}) => {
  const [overlapping, setOverlapping] = useState(false)
  const [sticky, setSticky] = useState(false)
  const [isHidden, setIsHidden] = useState(false)
  const [isBottom, setIsBottom] = useState(false)
  const [percentage, setPercentage] = useState(0)

  const artistSection = useRef(null)
  const shape = useRef(null)

  useEffect(() => {
    if (window.pageYOffset > 0) {
      setIsHidden(true)
    } else {
      setIsHidden(false)
    }
    if (shape && shape.current.getBoundingClientRect().top <= -100) {
      setSticky(false)
    } else {
      setSticky(true)
    }

    if (window.location.hash) {
      setOverlapping(true)
    }

    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      setIsBottom(true)
    } else {
      setIsBottom(false)
    }

    const percentage =
      window.pageYOffset /
      Math.max(
        (document.documentElement.clientHeight, window.innerHeight || 0) * 0.6
      )

    percentage > 0 ? setPercentage(percentage) : setPercentage(0)

    window.onscroll = function() {
      const percentage =
        window.pageYOffset /
        Math.max(
          (document.documentElement.clientHeight, window.innerHeight || 0) * 0.6
        )

      percentage > 0 ? setPercentage(percentage) : setPercentage(0)

      if (window.pageYOffset > 0) {
        setIsHidden(true)
      } else {
        setIsHidden(false)
      }
      if (shape && shape.current.getBoundingClientRect().top <= -100) {
        setSticky(false)
      } else {
        setSticky(true)
      }

      if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
        setIsBottom(true)
      } else {
        setIsBottom(false)
      }

      if (typeof window !== "undefined") {
        if (window.matchMedia("(max-height: 420px)").matches) {
          if (shape && shape.current.getBoundingClientRect().top <= 0) {
            setSticky(false)
          } else {
            setSticky(true)
          }
        }
        if (
          window.matchMedia("(min-width: 1024px) and (max-width: 1280px)")
            .matches
        ) {
          if (shape && shape.current.getBoundingClientRect().top <= -40) {
            setSticky(false)
          } else {
            setSticky(true)
          }
        }
      }
    }
    return () => {
      window.onscroll = null
    }
  }, [])

  return (
    <>
      <Hero
        logo={logo}
        overlapping={overlapping}
        setOverlapping={setOverlapping}
        isHidden={isHidden}
        percentage={percentage}
      />
      <Navbar isVisible={overlapping} sticky={sticky} isBottom={isBottom} />
      <div className="sticky-container">
        <About description={description} sticky={sticky} />
        <div className="blue-shape" ref={shape} />
        <section ref={artistSection} id="artists">
          {artists.map((artist, index) => (
            <Artist
              key={artist.name}
              name={artist.name}
              description={artist.description}
              image={artist.image}
              index={index}
            />
          ))}
        </section>
        <Contact contact={contact} />
      </div>
    </>
  )
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { logo, contact, description, artists, logoSmall } = frontmatter

  return (
    <Layout>
      <SEO title="Home" />
      <IndexTemplate
        logo={logo}
        contact={contact}
        description={description}
        artists={artists}
        logoSmall={logoSmall}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        description
        artists {
          name
          description
          image {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        contact {
          headline
          adress
          adress2
          email
          phonenumber
          website
        }
      }
    }
  }
`
